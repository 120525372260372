import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/why.scss"
import Section from "../components/section"
import SectionSubtitle from "../components/sectionSubtitle"
import Text from "../components/text"
import Footer from "../components/footer"

const Theraphy = () => (
  <Layout>
    <SEO
      lang="it"
      title="Terapia cognitivo comportamentale' | Stefano Maffina | Psicologo clinico | Gardone Val Trompia"
      description="La terapia cognitivo comportamentale di Stefano Maffina, psicologo clinico"
    />
    <div className="navbar paddings-lr">
      <div>
        <Link to="/">
          <span>back</span>
        </Link>
      </div>
    </div>
    <Section isAbout color="#f8f8f8">
      <div style={{ paddingTop: "64px" }}>
        <SectionSubtitle text="Approccio teorico e terapia cognitivo comportamentale" />
        <div className="paddings-lr why-content">
          <p>
            La terapia cognitivo comportamentale è attualmente considerata uno
            dei più efficaci approcci per la comprensione e il trattamento dei
            disturbi psicologici.
          </p>
          <p>
            Si basa sul presupposto che vi sia una correlazione tra pensieri,
            emozioni e comportamenti.
          </p>
          <p>
            La psicoterapia cognitivo comportamentale è un metodo
            scientificamente fondato, che si basa sulle conoscenze delle
            strutture e dei processi mentali pervenute dalla ricerca psicologica
            di base.
          </p>
          <p>
            Attualmente, questa strategia è considerata, a livello
            internazionale, uno dei più efficaci metodi per la comprensione ed
            il trattamento dei disturbi psicopatologici.
          </p>
          <p>
            L'efficacia di quest'approccio è stata dimostrata da studi
            scientifici realizzati in condizioni controllate, condotti con lo
            stesso rigore dei test effettuati per le terapie farmacologiche.
          </p>
          <p>
            Lo scopo della terapia è quello di riconoscere e superare ciò che
            genera sofferenza attraverso lo sviluppo di modalità di pensiero più
            adeguate e strategie comportamentali funzionali rispetto agli
            obiettivi del singolo individuo.
          </p>
          <p>
            Il trattamento si propone, quindi, di migliorare la qualità della
            vita dei pazienti ed aiutarli a gestire o risolvere l'eventuale
            psicopatologia.
          </p>
          <p>
            La psicoterapia cognitivo comportamentale fornisce gli strumenti per
            individuare gli schemi distorti di ragionamento e d'interpretazione
            della realtà, quindi li integra con pensieri e convinzioni
            funzionali e positive.
          </p>
        </div>
      </div>
    </Section>
    <Footer />
  </Layout>
)

export default Theraphy
